.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  max-width: 128px;
  max-height: 128px;
  border-radius: 50%;
  /* padding: 5px; */
  margin: 15px;
  border: #1c52a3 5px solid;
}

.datacamp-badges {
  height: 40vmin;
  pointer-events: none;
  max-width: 128px;
  max-height: 128px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-link {
  color: #61dafb;
} */

.stars {
  color: #FFD700;
}

h1.home-headers{
  font-size:  1.5rem;
}

h2.home-headers{
  font-size:  0.9rem;
}